<script>
export default defineNuxtComponent({
  name: 'RangeDatePicker',
  props: {
    label: {
      type: String,
      default: '',
      required: false,
    },
    range: {
      type: Boolean,
      required: false,
      default: false,
    },
    inline: {
      type: Boolean,
      required: false,
      default: false,
    },
    dateType: {
      type: String,
      required: false,
      default: 'date',
      validator: (value) => {
        return ['date', 'datetime'].includes(value) !== -1;
      },
    },
    placeholder: {
      type: String,
      required: false,
      default: '',
    },
    dateFormat: {
      type: String,
      required: false,
      default: '',
    },
    error: {
      type: String,
      required: false,
      default: '',
    },
    showConfirmButton: {
      type: Boolean,
      required: false,
      default: false,
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    defaultValue: {
      type: Array,
      required: false,
      default: () => [],
    },
    value: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      newDateValue: '',
    };
  },
  watch: {
    newDateValue() {
      this.selectDate();
    },
  },
  mounted() {
    this.newDateValue
      = this.defaultValue && this.defaultValue.length > 0
        ? [new Date(this.defaultValue[0]), new Date(this.defaultValue[1])]
        : new Date(this.value);
  },
  beforeMount() {
    this.listen();
  },
  beforeUnmount() {
    this.listen('off');
  },
  methods: {
    listen(status = 'on') {
      this.$eventBus[`$${status}`]('onClearSelection', this.clearSelection);
    },
    selectDate(confirmed) {
      this.$emit('selectDate', this.newDateValue, confirmed);
    },
    clearSelection() {
      this.newDateValue = '';
    },
    onClear() {
      this.selectDate(true);
    },
    onConfirm(value) {
      this.newDateValue = value;
      this.selectDate(true);
    },
  },
});
</script>

<template>
  <div>
    <div :class="{ hidden: !label }">
      <label
        v-if="label !== undefined"
        class="mb-2 inline-block font-medium dark:text-white"
      >{{ __(label) }}
        <span v-if="required" class="text-error-500">*</span>
      </label>
      <br>
    </div>
    <div class="flex h-14 w-full flex-col">
      <DatePicker
        id="rangeDatePicker"
        v-model:value="newDateValue"
        :type="dateType"
        :format="dateFormat"
        :range="range"
        :placeholder="placeholder"
        :class="{ 'border border-error': !!error }"
        :confirm="showConfirmButton"
        :confirm-text="__('Set Date')"
        @clear="onClear"
        @confirm="onConfirm"
      />
      <label v-show="!!error" class="py-2 pl-2 text-sm text-error">{{
        __(error)
      }}</label>
    </div>
  </div>
</template>

<style>
.mx-datepicker {
  /* @apply h-full w-full; */
}

.mx-datepicker-range {
  /* @apply h-full w-full; */
}

.mx-date-time-range {
  width: max-content !important;
  height: max-content !important;
}

.mx-input {
  @apply h-full w-full cursor-pointer
  border
  border-transparent
  bg-neutral-3
  text-base
  shadow-none
  hover:bg-neutral-2-200
  focus:border-primary
  focus:bg-neutral-2-200
}

.mx-input-wrapper {
  /* @apply h-full; */
}

.mx-datepicker svg {
  @apply cursor-pointer fill-current accent-font;
}

.mx-input:focus {
  @apply accent-border;
}

.mx-calendar-content .cell.active {
  @apply accent-background;
}

.mx-table-date .today {
  @apply accent-font;
}

.mx-time-column .mx-time-item.active {
  @apply accent-font;
}

.mx-time-option.active {
  @apply accent-font;
}

.mx-icon-calendar,
.mx-icon-clear {
  @apply right-5;
}
</style>
